<template>
  <div class="pageContent">
    <searchHeader />
    <div class="financialSettlement">
      <div class="financialBanner"></div>
      <div class="financialgyl">
        <div class="title"></div>
        <div class="listBox">
          <!-- 订单融资 -->
          <div class="list">
            <div class="listLeft">
              <div class="listTitle jyljr_ico2"><i></i>产品优势</div>
              <div class="cplist clearfix">
                <ul>
                  <li class="jyljl_ico9"><a><i></i><span>无需担保和抵押</span></a></li>
                  <li class="jyljl_ico10"><a><i></i><span>一次授信循环使用</span></a></li>
                  <li class="jyljl_ico11"><a><i></i><span>融资成本低</span></a></li>
                </ul>
              </div>
            </div>
            <div class="listRight bg1">
              <h2>订单融资</h2>
              <router-link tag="li" to="/financial/order">
                <p class="details">
                  了解详情
                </p>
              </router-link>
            </div>
          </div>
          <!-- 应收账款融资 -->
          <div class="list">
            <div class="listLeft">
              <div class="listTitle jyljr_ico3"><i></i>产品优势</div>
              <div class="cplist clearfix">
                <ul>
                  <li class="jyljl_ico12"><a><i></i><span>融资成本低</span></a></li>
                  <li class="jyljl_ico13"><a><i></i><span>操作便捷</span></a></li>
                  <li class="jyljl_ico14"><a><i></i><span>有效解决资金周转</span></a></li>
                </ul>
              </div>
            </div>
            <div class="listRight bg2">
              <h2>应收账款融资</h2>
              <router-link tag="li" to="/financial/accountReceive">
                <p class="details">
                  了解详情
                </p>
              </router-link>
            </div>
          </div>
          <!-- 仓单质押融资 -->
          <div class="list">
            <div class="listLeft">
              <div class="listTitle jyljr_ico4"><i></i>产品优势</div>
              <div class="cplist clearfix">
                <ul>
                  <li class="jyljl_ico15"><a><i></i><span>无需担保</span></a></li>
                  <li class="jyljl_ico16"><a><i></i><span>增加资金使用率</span></a></li>
                  <li class="jyljl_ico17"><a><i></i><span>操作快捷</span></a></li>
                </ul>
              </div>
            </div>
            <div class="listRight bg3">
              <h2>仓单质押融资</h2>
              <router-link tag="li" to="/financial/warehouse">
                <p class="details">
                  了解详情
                </p>
              </router-link>
            </div>
          </div>
          <!-- 票据融资 -->
          <div class="list">
            <div class="listLeft">
              <div class="listTitle jyljr_ico5"><i></i>产品优势</div>
              <div class="cplist clearfix">
                <ul>
                  <li class="jyljl_ico18"><a><i></i><span>流转便捷</span></a></li>
                  <li class="jyljl_ico19"><a><i></i><span>贴现率低</span></a></li>
                  <li class="jyljl_ico20"><a><i></i><span>融资成本低</span></a></li>
                </ul>
              </div>
            </div>
            <div class="listRight bg4">
              <h2>票据融资</h2>
              <router-link tag="li" to="/financial/bill">
                <p class="details">了解详情</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="financialjg">
        <div class="financialjgBox">
          <div class="jrjs_tit3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";

export default {
  components: {
    searchHeader
  }
}
</script>
<style scoped lang="scss">
.financialSettlement .financialBanner {
  width: 100%;
  height: 500px;
  background: url('../../assets/configImages/financialBanner.jpg') center center no-repeat;
  background-size: cover;
}
.financialSettlement .financialgyl {
  width: 100%;
  height: 1330px;
  background: url('../../assets/configImages/jr_bg.jpg') center center no-repeat;
  background-size: cover;
  overflow: hidden;
}
.financialSettlement .financialgyl .title {
  margin: 30px auto;
  width: 556px;
  height: 39px;
  background: url('../../assets/configImages/jr_tit2.png') center no-repeat;
}
.listBox {
  margin: 0 auto;
  width: 1300px;
}
.listBox .list {
  overflow: hidden;
}
.listBox .list .listLeft {
  width: 949px;
  background: #fff;
  height: 254px;
  float: left;
  border-radius: 10px 0 0 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
}
.listBox .list .listRight {
  height: 274px;
  float: right;
  width: 211px;
  background: url('../../assets/configImages/jr_cp_bg.png') no-repeat;
  margin-bottom: 20px;
}
.listBox .list .listRight.bg1 {
  background-position: 0 -290px;
}
.listBox .list .listRight.bg2 {
  background-position: 0 -580px;
}
.listBox .list .listRight.bg3 {
  background-position: 0 -867px;
}
.listBox .list .listRight.bg4 {
  background-position: 0 -1159px;
}
.listBox .list .listLeft .listTitle {
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  color: #444;
}
.listBox .list .listLeft .listTitle i {
  display: inline-block;
  width: 7px;
  height: 40px;
  background: url('../../assets/configImages/jr_icons.png');
  vertical-align: -12px;
  margin-right: 10px;
}
.listBox .list .listLeft .jyljr_ico2 i {
  background-position: -16px 0;
}
.listBox .list .listLeft .jyljr_ico3 i {
  background-position: -33px 0;
}
.listBox .list .listLeft .jyljr_ico4 i {
  background-position: -49px 0;
}
.listBox .list .listLeft .jyljr_ico5 i {
  background-position: -65px 0;
}
.cplist {
  width: 810px;
  margin: 10px auto 3px auto;
  overflow: hidden;
}
.cplist li {
  float: left;
  width: 270px;
  text-align: center;
}
.cplist li i {
  display: block;
  width: 94px;
  height: 94px;
  background: url('../../assets/configImages/jr_icons.png');
  margin: auto;
}
.cplist .jyljl_ico9 i {
  background-position: 0 -168px;
}
.cplist .jyljl_ico10 i {
  background-position: -96px -169px;
}
.cplist .jyljl_ico11 i {
  background-position: -192px -169px;
}
.cplist .jyljl_ico12 i {
  background-position: 0 -274px;
}
.cplist .jyljl_ico13 i {
  background-position: -96px -274px;
}
.cplist .jyljl_ico14 i {
  background-position: -192px -274px;
}
.cplist .jyljl_ico15 i {
  background-position: 0 -380px;
}
.cplist .jyljl_ico16 i {
  background-position: -96px -380px;
}
.cplist .jyljl_ico17 i {
  background-position: -192px -380px;
}
.cplist .jyljl_ico18 i {
  background-position: 0 -486px;
}
.cplist .jyljl_ico19 i {
  background-position: -96px -486px;
}
.cplist .jyljl_ico20 i {
  background-position: -192px -486px;
}
.cplist li span {
  font-size: 18px;
  color: #222;
  line-height: 60px;
}
.listBox .list .listRight h2 {
  font-size: 30px;
  color: #fff;
  text-align: center;
  margin-top: 130px;
}
.listBox .list .listRight .details {
  display: block;
  width: 150px;
  margin: auto;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #fff;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  margin-top: 20px;
  cursor: pointer;
}
.financialSettlement .financialjg {
  background: #f3f2f6;
  padding: 15px 0 30px 0;
  width: 100%;
}
.financialSettlement .financialjg .financialjgBox {
  width: 1300px;
  margin: auto;
  position: relative;
}
.financialSettlement .financialjg .financialjgBox .jrjs_tit3 {
  background: url('../../assets/configImages/jr_tit3.png') no-repeat center;
  width: 556px;
  height: 39px;
  margin: 30px auto;
}
</style>